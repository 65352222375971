"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColonyBenefit = void 0;
var ColonyBenefit;
(function (ColonyBenefit) {
    ColonyBenefit[ColonyBenefit["ADD_RESOURCES_TO_CARD"] = 0] = "ADD_RESOURCES_TO_CARD";
    ColonyBenefit[ColonyBenefit["ADD_RESOURCES_TO_VENUS_CARD"] = 1] = "ADD_RESOURCES_TO_VENUS_CARD";
    ColonyBenefit[ColonyBenefit["COPY_TRADE"] = 2] = "COPY_TRADE";
    ColonyBenefit[ColonyBenefit["DRAW_CARDS"] = 3] = "DRAW_CARDS";
    ColonyBenefit[ColonyBenefit["DRAW_CARDS_AND_BUY_ONE"] = 4] = "DRAW_CARDS_AND_BUY_ONE";
    ColonyBenefit[ColonyBenefit["DRAW_CARDS_AND_DISCARD_ONE"] = 5] = "DRAW_CARDS_AND_DISCARD_ONE";
    ColonyBenefit[ColonyBenefit["DRAW_CARDS_AND_KEEP_ONE"] = 6] = "DRAW_CARDS_AND_KEEP_ONE";
    ColonyBenefit[ColonyBenefit["GAIN_CARD_DISCOUNT"] = 7] = "GAIN_CARD_DISCOUNT";
    ColonyBenefit[ColonyBenefit["GAIN_PRODUCTION"] = 8] = "GAIN_PRODUCTION";
    ColonyBenefit[ColonyBenefit["GAIN_RESOURCES"] = 9] = "GAIN_RESOURCES";
    ColonyBenefit[ColonyBenefit["GAIN_SCIENCE_TAG"] = 10] = "GAIN_SCIENCE_TAG";
    ColonyBenefit[ColonyBenefit["GAIN_TR"] = 11] = "GAIN_TR";
    ColonyBenefit[ColonyBenefit["GAIN_VP"] = 12] = "GAIN_VP";
    ColonyBenefit[ColonyBenefit["INCREASE_VENUS_SCALE"] = 13] = "INCREASE_VENUS_SCALE";
    ColonyBenefit[ColonyBenefit["LOSE_RESOURCES"] = 14] = "LOSE_RESOURCES";
    ColonyBenefit[ColonyBenefit["OPPONENT_DISCARD"] = 15] = "OPPONENT_DISCARD";
    ColonyBenefit[ColonyBenefit["PLACE_OCEAN_TILE"] = 16] = "PLACE_OCEAN_TILE";
    ColonyBenefit[ColonyBenefit["STEAL_RESOURCES"] = 17] = "STEAL_RESOURCES";
    ColonyBenefit[ColonyBenefit["GAIN_INFLUENCE"] = 18] = "GAIN_INFLUENCE";
    ColonyBenefit[ColonyBenefit["PLACE_DELEGATES"] = 19] = "PLACE_DELEGATES";
    ColonyBenefit[ColonyBenefit["GIVE_MC_PER_DELEGATE"] = 20] = "GIVE_MC_PER_DELEGATE";
    ColonyBenefit[ColonyBenefit["GAIN_SCIENCE_TAGS_AND_CLONE_TAG"] = 21] = "GAIN_SCIENCE_TAGS_AND_CLONE_TAG";
    ColonyBenefit[ColonyBenefit["RAISE_PLANETARY_TRACK"] = 22] = "RAISE_PLANETARY_TRACK";
    ColonyBenefit[ColonyBenefit["PLACE_HAZARD_TILE"] = 23] = "PLACE_HAZARD_TILE";
    ColonyBenefit[ColonyBenefit["ERODE_SPACES_ADJACENT_TO_HAZARDS"] = 24] = "ERODE_SPACES_ADJACENT_TO_HAZARDS";
    ColonyBenefit[ColonyBenefit["GAIN_MC_PER_HAZARD_TILE"] = 25] = "GAIN_MC_PER_HAZARD_TILE";
    ColonyBenefit[ColonyBenefit["GAIN_CORRUPTION"] = 26] = "GAIN_CORRUPTION";
})(ColonyBenefit = exports.ColonyBenefit || (exports.ColonyBenefit = {}));
