"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COLONY_DESCRIPTIONS = void 0;
const ColonyName_1 = require("./ColonyName");
exports.COLONY_DESCRIPTIONS = {
    [ColonyName_1.ColonyName.CALLISTO]: 'Energy',
    [ColonyName_1.ColonyName.CERES]: 'Steel',
    [ColonyName_1.ColonyName.ENCELADUS]: 'Microbes',
    [ColonyName_1.ColonyName.EUROPA]: 'Production',
    [ColonyName_1.ColonyName.GANYMEDE]: 'Plants',
    [ColonyName_1.ColonyName.IO]: 'Heat',
    [ColonyName_1.ColonyName.LUNA]: 'MegaCredits',
    [ColonyName_1.ColonyName.MIRANDA]: 'Animals',
    [ColonyName_1.ColonyName.PLUTO]: 'Cards',
    [ColonyName_1.ColonyName.TITAN]: 'Floaters',
    [ColonyName_1.ColonyName.TRITON]: 'Titanium',
    [ColonyName_1.ColonyName.IAPETUS]: 'TR',
    [ColonyName_1.ColonyName.MERCURY]: 'Production',
    [ColonyName_1.ColonyName.HYGIEA]: 'Attack',
    [ColonyName_1.ColonyName.TITANIA]: 'VP',
    [ColonyName_1.ColonyName.VENUS]: 'Venus',
    [ColonyName_1.ColonyName.LEAVITT]: 'Science',
    [ColonyName_1.ColonyName.PALLAS]: 'Politics',
    [ColonyName_1.ColonyName.LEAVITT_II]: 'Science & Clone Tags',
    [ColonyName_1.ColonyName.IAPETUS_II]: 'Data',
    [ColonyName_1.ColonyName.DEIMOS]: 'Hazards',
    [ColonyName_1.ColonyName.ENRON]: 'Corruption',
};
