"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const AppButton_vue_1 = require("@/client/components/common/AppButton.vue");
const ClientCardManifest_1 = require("@/client/cards/ClientCardManifest");
const CardName_1 = require("@/common/cards/CardName");
const constants = require("@/common/constants");
const SelectCard_vue_1 = require("@/client/components/SelectCard.vue");
const ConfirmDialog_vue_1 = require("@/client/components/common/ConfirmDialog.vue");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
const Tag_1 = require("@/common/cards/Tag");
const CardType_1 = require("@/common/cards/CardType");
const Colony_vue_1 = require("@/client/components/colonies/Colony.vue");
const titles = require("@/common/inputs/SelectInitialCards");
const utils_1 = require("@/common/utils/utils");
exports.default = vue_1.default.extend({
    name: 'SelectInitialCards',
    props: {
        playerView: {
            type: Object,
        },
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
        preferences: {
            type: Object,
            default: () => PreferencesManager_1.PreferencesManager.INSTANCE.values(),
        },
    },
    components: {
        AppButton: AppButton_vue_1.default,
        SelectCard: SelectCard_vue_1.default,
        'confirm-dialog': ConfirmDialog_vue_1.default,
        Colony: Colony_vue_1.default,
    },
    data() {
        return {
            selectedCards: [],
            selectedCeos: [],
            selectedCorporations: [],
            selectedPreludes: [],
            valid: false,
            warning: undefined,
        };
    },
    methods: {
        noop() {
            throw new Error('should not be called');
        },
        getAfterPreludes() {
            return (0, utils_1.sum)(this.selectedPreludes.map((prelude) => {
                const card = (0, ClientCardManifest_1.getCardOrThrow)(prelude);
                const base = card.startingMegaCredits ?? 0;
                return base + this.extra(prelude);
            }));
        },
        extra(prelude) {
            const card = (0, ClientCardManifest_1.getCardOrThrow)(prelude);
            switch (this.selectedCorporations.length === 1 ? this.selectedCorporations[0] : undefined) {
                case CardName_1.CardName.MANUTECH:
                    return card.productionBox?.megacredits ?? 0;
                case CardName_1.CardName.THARSIS_REPUBLIC:
                    switch (prelude) {
                        case CardName_1.CardName.SELF_SUFFICIENT_SETTLEMENT:
                        case CardName_1.CardName.EARLY_SETTLEMENT:
                        case CardName_1.CardName.STRATEGIC_BASE_PLANNING:
                            return 3;
                    }
                    return 0;
                case CardName_1.CardName.PHARMACY_UNION:
                    const tags = card.tags.filter((tag) => tag === Tag_1.Tag.MICROBE).length;
                    return (-4 * tags);
                case CardName_1.CardName.SPLICE:
                    const microbeTags = card.tags.filter((tag) => tag === Tag_1.Tag.MICROBE).length;
                    return (2 * microbeTags);
                case CardName_1.CardName.APHRODITE:
                    switch (prelude) {
                        case CardName_1.CardName.VENUS_FIRST:
                            return 4;
                        case CardName_1.CardName.HYDROGEN_BOMBARDMENT:
                            return 2;
                    }
                    return 0;
                case CardName_1.CardName.LUNA_FIRST_INCORPORATED:
                    switch (prelude) {
                        case CardName_1.CardName.FIRST_LUNAR_SETTLEMENT:
                        case CardName_1.CardName.CORE_MINE:
                        case CardName_1.CardName.BASIC_INFRASTRUCTURE:
                            return 1;
                        case CardName_1.CardName.MINING_COMPLEX:
                            return 2;
                    }
                    return 0;
                case CardName_1.CardName.POLARIS:
                    switch (prelude) {
                        case CardName_1.CardName.AQUIFER_TURBINES:
                        case CardName_1.CardName.POLAR_INDUSTRIES:
                            return 4;
                        case CardName_1.CardName.GREAT_AQUIFER:
                            return 8;
                    }
                    return 0;
                case CardName_1.CardName.HEAD_START:
                    return this.selectedCards.length * 2;
                case CardName_1.CardName.SAGITTA_FRONTIER_SERVICES:
                    const count = card.tags.filter((tag) => tag !== Tag_1.Tag.WILD).length;
                    return count === 0 ? 4 : count === 1 ? 1 : 0;
                default:
                    return 0;
            }
        },
        getStartingMegacredits() {
            if (this.selectedCorporations.length !== 1) {
                return NaN;
            }
            const corpName = this.selectedCorporations[0];
            const corporation = (0, ClientCardManifest_1.getCardOrThrow)(corpName);
            let starting = corporation.startingMegaCredits ?? 0;
            const cardCost = corporation.cardCost === undefined ? constants.CARD_COST : corporation.cardCost;
            starting -= this.selectedCards.length * cardCost;
            return starting;
        },
        saveIfConfirmed() {
            const projectCards = this.selectedCards.filter((name) => (0, ClientCardManifest_1.getCard)(name)?.type !== CardType_1.CardType.PRELUDE);
            let showAlert = false;
            if (this.preferences.show_alerts && projectCards.length === 0)
                showAlert = true;
            if (showAlert) {
                this.$refs.confirmation.show();
            }
            else {
                this.saveData();
            }
        },
        saveData() {
            const result = {
                type: 'initialCards',
                responses: [],
            };
            if (this.selectedCorporations.length === 1) {
                result.responses.push({
                    type: 'card',
                    cards: [this.selectedCorporations[0]],
                });
            }
            if (this.hasPrelude) {
                result.responses.push({
                    type: 'card',
                    cards: this.selectedPreludes,
                });
            }
            if (this.hasCeo) {
                result.responses.push({
                    type: 'card',
                    cards: this.selectedCeos,
                });
            }
            result.responses.push({
                type: 'card',
                cards: this.selectedCards,
            });
            this.onsave(result);
        },
        cardsChanged(cards) {
            this.selectedCards = cards;
            this.validate();
        },
        ceosChanged(cards) {
            this.selectedCeos = cards;
            this.validate();
        },
        corporationChanged(cards) {
            this.selectedCorporations = cards;
            this.validate();
        },
        preludesChanged(cards) {
            this.selectedPreludes = cards;
            this.validate();
        },
        calcuateWarning() {
            this.warning = undefined;
            if (this.selectedCorporations.length === 0) {
                this.warning = 'Select a corporation';
                return false;
            }
            if (this.selectedCorporations.length > 1) {
                this.warning = 'You selected too many corporations';
                return false;
            }
            if (this.hasPrelude) {
                if (this.selectedPreludes.length < 3) {
                    this.warning = 'Select 3 preludes';
                    return false;
                }
                if (this.selectedPreludes.length > 3) {
                    this.warning = 'You selected too many preludes';
                    return false;
                }
            }
            if (this.hasCeo) {
                if (this.selectedCeos.length < 1) {
                    this.warning = 'Select 1 CEO';
                    return false;
                }
                if (this.selectedCeos.length > 1) {
                    this.warning = 'You selected too many CEOs';
                    return false;
                }
            }
            if (this.selectedCards.length === 0) {
                this.warning = 'You haven\'t selected any project cards';
                return true;
            }
            return true;
        },
        validate() {
            this.valid = this.calcuateWarning();
        },
        confirmSelection() {
            this.saveData();
        },
        getColony(colonyName) {
            return {
                colonies: [],
                isActive: false,
                name: colonyName,
                trackPosition: 0,
                visitor: undefined,
            };
        },
    },
    computed: {
        playerCanChooseAridor() {
            return this.playerView.dealtCorporationCards.some((card) => card.name === CardName_1.CardName.ARIDOR);
        },
        hasPrelude() {
            return hasOption(this.playerinput.options, titles.SELECT_PRELUDE_TITLE);
        },
        hasCeo() {
            return hasOption(this.playerinput.options, titles.SELECT_CEO_TITLE);
        },
        corpCardOption() {
            const option = getOption(this.playerinput.options, titles.SELECT_CORPORATION_TITLE);
            if ((0, PreferencesManager_1.getPreferences)().experimental_ui) {
                option.min = 1;
                option.max = option.cards.length;
            }
            return option;
        },
        preludeCardOption() {
            const option = getOption(this.playerinput.options, titles.SELECT_PRELUDE_TITLE);
            if ((0, PreferencesManager_1.getPreferences)().experimental_ui) {
                option.max = option.cards.length;
            }
            return option;
        },
        ceoCardOption() {
            const option = getOption(this.playerinput.options, titles.SELECT_CEO_TITLE);
            if ((0, PreferencesManager_1.getPreferences)().experimental_ui) {
                option.max = option.cards.length;
            }
            return option;
        },
        projectCardOption() {
            return getOption(this.playerinput.options, titles.SELECT_PROJECTS_TITLE);
        },
    },
    mounted() {
        this.validate();
    },
});
function getOption(options, title) {
    const option = options.find((option) => option.title === title);
    if (option === undefined) {
        throw new Error('invalid input, missing option');
    }
    if (option.type !== 'card') {
        throw new Error('invalid input, Not a SelectCard option');
    }
    return option;
}
function hasOption(options, title) {
    const option = options.find((option) => option.title === title);
    return option !== undefined;
}
